import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router'
import i18n from './i18n';
import Vuesax from 'vuesax'
import VueMeta from 'vue-meta'
//import VueLazyload from 'vue-lazyload';
import VueClipboard from 'vue-clipboard2'
import VueSweetalert2 from 'vue-sweetalert2';
import 'vuesax/dist/vuesax.css'
import 'material-icons/iconfont/material-icons.css';
import 'hover.css/css/hover-min.css';
import 'vue-animate-transitions/dist/vue-animate-transitions.min.css';
import 'sweetalert2/dist/sweetalert2.min.css';


Vue.config.productionTip = false

Vue.use(Vuesax, {
        // options here
    })
    //Vue.use(VueLazyload, {});
Vue.use(VueMeta, {
    refreshOnceOnNavigation: true,
});
Vue.use(VueClipboard)
Vue.use(VueSweetalert2)

new Vue({
    vuetify,
    router,
    i18n,
    render: h => h(App)
}).$mount('#app')